import React, { useState, useEffect } from "react";
import Background from "./background.png";
import Logo from "./logo.png";
import {
  Button,
  TextInput,
  FormControl,
  Select,
  Checkbox,
  Textarea,
  Box,
  ToggleSwitch,
} from "@primer/react";
import firebase from "./firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import { ReplyIcon } from "@primer/octicons-react";
import { useMediaQuery } from "react-responsive";

export default function Social() {

  return (
    <div style={{ overflowX: "hidden" }}>
      <div
        style={{
          backgroundImage: `url(${Background})`,
          width: "100%",
          height: "600px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom center",
        }}
      >
        <div style={{backgroundColor: 'white', width: '60%', paddingTop: '50px', paddingBottom: '50px', marginLeft: '20%', borderRadius: '20px', marginTop: '100px', position: 'absolute'}}>
            <h1 style={{ fontSize: "25px" }}>Social Media Graphics</h1>
            <p style={{width: '80%', marginLeft: '10%'}}>Spread the word about your acceptance on Social Media!! Getting accepted to a hackathon is no small feat and recruiters from companies love to see that you are taking the initiative when making hiring decisions. Share the following graphic on LinkedIn, Twitter, or any other social media platforms.</p>
            <div style={{display: 'flex', gap: '10px', justifyContent: 'center', flexWrap: 'wrap'}}>
            <Button onClick={() => {
                const downloadImage = () => {
                    // Define the URL of the image
                    const imageURL = `${process.env.PUBLIC_URL}/post.png`;
                
                    // Create a new anchor element
                    const link = document.createElement('a');
                
                    // Set the href and download attributes of the link
                    link.href = imageURL;
                    link.download = 'post.png';
                
                    // Append the link to the DOM and simulate a click to start the download
                    document.body.appendChild(link);
                    link.click();
                
                    // Remove the link from the DOM
                    document.body.removeChild(link);
                };
                downloadImage();
            }}>Download Post</Button>
            <Button onClick={() => {
                const downloadImage = () => {
                    // Define the URL of the image
                    const imageURL = `${process.env.PUBLIC_URL}/story.png`;
                
                    // Create a new anchor element
                    const link = document.createElement('a');
                
                    // Set the href and download attributes of the link
                    link.href = imageURL;
                    link.download = 'story.png';
                
                    // Append the link to the DOM and simulate a click to start the download
                    document.body.appendChild(link);
                    link.click();
                
                    // Remove the link from the DOM
                    document.body.removeChild(link);
                };
                downloadImage();
            }}>Download Story</Button>
            </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
}

