import React from "react";
import Background from "./background.png";
import Logo from "./logo.png";
import Dice1 from "./dice1.png";
import Dice2 from "./dice2.png";
import Crystal from "./Crystal.png";
import Dagger from "./Dagger.png";
import Dice from "./Dice.png";
import Spellbook from "./Spellbook.png";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Banner from "./banner.png";
import { useMediaQuery } from "react-responsive";
import MorganMorgan from "./morganmorgan.png";
import Siemens from "./siemens.png";
import Geico from "./geico.png";
import Microsoft from "./microsoft.png";
import RBC from "./rbc.png";
import ToolCharm from "./toolcharm.png";
import Lockheed from "./lockheed.png";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: "10px",
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function Home() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <div style={{ overflowX: "hidden" }}>
      <img
        src={Banner}
        style={{
          width: isTabletOrMobile ? "85px" : "100px",
          position: "absolute",
          zIndex: "500",
          right: 0,
          cursor: "pointer",
        }}
        onClick={() => {
          window.open("https://mlh.io/seasons/2024/events", "_blank");
        }}
      />
      <div
        style={{
          backgroundImage: `url(${Background})`,
          width: "100%",
          height: "600px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom center",
        }}
      ></div>
      <div style={{ marginTop: "-620px" }}>
        <div
          style={{
            backgroundColor: "white",
            width: "90%",
            marginLeft: "5%",
            height: "50px",
            borderRadius: "15px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
            paddingLeft: "5px",
            paddingRight: "5px",
            position: "fixed",
            marginTop: "-120px",
            boxShadow: "0px 4px 20px 1px rgba(122, 122, 122, 0.25)",
            zIndex: "200",
          }}
        >
          <img src={Logo} style={{ width: "170px", height: "45px" }} />
          <div style={{ paddingRight: "20px" }}>
            <button
              style={{
                backgroundColor: "#1D1234",
                color: "white",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "10px",
                paddingBottom: "10px",
                fontSize: "15px",
                fontWeight: "900",
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
                marginRight: isTabletOrMobile ? "20px" : "15  px",
              }}
              onClick={() => {
                window.location.href = "/auth";
              }}
            >
              Register Now
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginTop: "160px",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              backgroundColor: "#1D1234",
              color: "white",
              paddingLeft: "5px",
              paddingRight: "5px",
              fontSize: "15px",
              fontWeight: "700",
              border: "none",
              borderRadius: "30px",
              width: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "40px",
            }}
          >
            <h4 style={{ marginTop: "20px" }}>October 6-8, 2023</h4>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "35px",
            }}
          >
            <img src={Dice1} style={{ marginTop: "-40px", width: "150px" }} />
            <h1
              style={{
                color: "white",
                fontSize: "45px",
                fontWeight: "900",
                width: "350px",
                marginTop: "20px",
              }}
            >
              Choose Your Own Adventure
            </h1>
            <img src={Dice2} style={{ marginTop: "-40px", width: "150px" }} />
          </div>
          <p
            style={{
              color: "white",
              fontWeight: "700",
              fontSize: "22px",
              marginTop: "-10px",
            }}
          >
            A ✨fantasy-themed✨ hackathon at the University of Central Florida
          </p>
          <div
            style={{
              display: "flex",
              gap: "40px",
              flexWrap: "wrap",
              justifyContent: "center",
              marginTop: "30px",
              width: "80%",
            }}
          >
            <div
              style={{
                border: "3px solid #1D1234",
                boxShadow: "10px 10px 0px 5px #1D1234",
                borderRadius: "20px",
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                gap: "20px",
                paddingLeft: "50px",
                paddingRight: "50px",
              }}
            >
              <div
                style={{
                  width: "35px",
                  height: "35px",
                  backgroundColor: "#D9D0EA",
                  borderRadius: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                }}
              >
                🏆
              </div>
              <h1 style={{ fontSize: "27px" }}>Compete</h1>
            </div>
            <div
              style={{
                border: "3px solid #1D1234",
                boxShadow: "10px 10px 0px 5px #1D1234",
                borderRadius: "20px",
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                gap: "20px",
                paddingLeft: "50px",
                paddingRight: "50px",
              }}
            >
              <div
                style={{
                  width: "35px",
                  height: "35px",
                  backgroundColor: "#D9D0EA",
                  borderRadius: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                }}
              >
                🧠
              </div>
              <h1 style={{ fontSize: "27px" }}>Learn</h1>
            </div>
            <div
              style={{
                border: "3px solid #1D1234",
                boxShadow: "10px 10px 0px 5px #1D1234",
                borderRadius: "20px",
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                gap: "20px",
                paddingLeft: "50px",
                paddingRight: "50px",
              }}
            >
              <div
                style={{
                  width: "35px",
                  height: "35px",
                  backgroundColor: "#D9D0EA",
                  borderRadius: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                }}
              >
                💼
              </div>
              <h1 style={{ fontSize: "27px" }}>Network & Have Fun!</h1>
            </div>
          </div>
          <div>
            <h2 style={{ fontSize: "30px", marginTop: "150px" }}>
              About Knight Hacks
            </h2>
            <p
              style={{
                width: "65%",
                marginLeft: "17.5%",
                textAlign: "left",
                fontSize: "17px",
                fontWeight: "500",
              }}
            >
              Ready to kickstart your career in tech? Join us for Knighthacks!
              Students from around the world will come together to learn the
              latest technologies, develop innovative solutions, network with
              top companies, and more! This year, we are excited to announce two
              features to this year's hackathon; we are an MLH season starter
              hackathon and we have partnered with Hack@UCF to bring you the
              Horse Plinko Cyber Challenge, a blue team vs. red team competition
              as well as cybersecurity convention! Along with all our amazing
              workshops, you also have the chance work together to build
              exciting projects, meet recruiters and land job opportunities, win
              prizes, get swag, and have fun!
            </p>
          </div>
          <div style={{ width: "70%" }}>
            <h2 style={{ fontSize: "30px", marginTop: "120px" }}>
              Frequently Asked Questions
            </h2>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>What is a Hackathon?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  A hackathon is a weekend-long event where students come
                  together to learn the latest technologies and build innovative
                  projects. These projects can range from involving web or
                  mobile development to building a hardware project, or anything
                  in between. Hackathons are a great way to learn new skills,
                  meet new people, and have fun! Additionally, throughout the
                  weekend we will be hosting workshops, fun social events,
                  networking opportunities with sponsors, free food & swag, and
                  so much more.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <Typography>How long is Knight Hacks?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Knighthacks is a 36-hour hackathon, beginning at 5pm on Friday
                  (check-in runs from 5-8pm, with the opening ceremony starting
                  at 8pm) and ending at 6pm on Sunday. We encourage you to work
                  on your project for as long as you can during this time.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                <Typography>Who can attend?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  If you’re currently a college student or have graduated in the
                  past year, you're more than welcome to attend!
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                aria-controls="panel4d-content"
                id="panel4d-header"
              >
                <Typography>How much experience do I need?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  None! We welcome students from all academic backgrounds and
                  skill levels, so don’t be afraid to come and join us! We’ll
                  have introductory workshops for you to learn new skills,
                  industry mentors to help you out, and great tools to build
                  your projects. Whether you’ve never coded before or have lots
                  of experience, there’s a place for you at KnightHacks!
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                aria-controls="panel5d-content"
                id="panel5d-header"
              >
                <Typography>Do I need to have a team?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Not at all! You can be a lone wolf, come with a team (no more
                  than four people), or join some teams at KnightHacks. We’ll
                  also have team building activities to help you find the right
                  teammates!
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel9"}
              onChange={handleChange("panel9")}
            >
              <AccordionSummary
                aria-controls="panel5d-content"
                id="panel5d-header"
              >
                <Typography>
                  Will there be transportation provided or travel
                  reimbursements?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Good news! We are planning on sending busses to the following
                  schools: <br /> <br />
                  1. Florida International University (FIU)
                  <br /> 2. University of Florida (UF)
                  <br /> 3. University of South Florida (USF) <br /> <br />
                  More information will be provided closer to the event to you
                  via email if you are registered, and you attend one of these
                  universities.
                  <br /> <br />
                  Unfortunately due to budget constraints this year, we will not
                  be able to provide travel reimbursements beyond this. We
                  apologize for the inconvenience.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                aria-controls="panel6d-content"
                id="panel6d-header"
              >
                <Typography>How much does it cost?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Nothing! That’s right, KnightHacks is entirely free for all
                  attendees to participate. All you need to worry about is
                  learning new skills, developing cool projects, and having fun!
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
            >
              <AccordionSummary
                aria-controls="panel7d-content"
                id="panel7d-header"
              >
                <Typography>
                  What is the Horse Plinko Cyber Challenge?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Horse Plinko Cyber Challenge (HPCC) is a beginner-oriented
                  cybersecurity competition run by Hack@UCF. HPCC is a blue team
                  competition, where the blue team is given a real world
                  scenario that puts them in charge of securing a fictitious
                  company’s computers.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8")}
            >
              <AccordionSummary
                aria-controls="panel8d-content"
                id="panel8d-header"
              >
                <Typography>
                  Can I participate in both the Hackathon and Horse Plinko?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Absolutely! Knighthacks encourages attendees to explore and
                  try new things.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div>
          <h2 style={{ fontSize: "30px", marginTop: "120px" }}>Sponsors</h2>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "80px",
              alignItems: "center",
              marginTop: "50px",
              padding: "100px",
            }}
          >
            <img
              src={MorganMorgan}
              style={{ width: "200px", height: "40px", cursor: "pointer" }}
              onClick={() => {
                window.open("https://www.forthepeople.com/", "_blank");
              }}
            />
            <img
              src={Siemens}
              style={{ width: "200px", height: "80px", cursor: "pointer" }}
              onClick={() => {
                window.open(
                  "https://www.siemens-energy.com/global/en.html",
                  "_blank"
                );
              }}
            />
            <img
              src={Geico}
              style={{ width: "200px", cursor: "pointer" }}
              onClick={() => {
                window.open("https://www.geico.com/", "_blank");
              }}
            />
            <img
              src={Microsoft}
              style={{ width: "200px", cursor: "pointer" }}
              onClick={() => {
                window.open("https://www.microsoft.com/en-us/", "_blank");
              }}
            />
            <img
              src={RBC}
              style={{ width: "200px", cursor: "pointer" }}
              onClick={() => {
                window.open("https://www.rbc.com/", "_blank");
              }}
            />
            <img
              src={Lockheed}
              style={{ width: "250px", cursor: "pointer" }}
              onClick={() => {
                window.open("https://www.lockheedmartin.com/", "_blank");
              }}
            />
            <img
              src={ToolCharm}
              style={{ width: "200px", cursor: "pointer" }}
              onClick={() => {
                window.open("https://www.toolcharm.com/", "_blank");
              }}
            />
            <p
              style={{ fontWeight: "700", fontSize: "25px", color: "#656565" }}
            >
              more sponsors being announced soon...
            </p>
          </div>
        </div>
        <div
          style={{
            background: "linear-gradient(180deg, #FFF 0%, #E9DFFF 100%)",
            height: "600px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "200px",
          }}
        >
          <div style={{ display: "flex", gap: "800px" }}>
            <img src={Crystal} style={{ width: "150px" }} />
            <img src={Dagger} style={{ width: "150px" }} />
          </div>
          <h1
            style={{
              fontWeight: "900",
              fontSize: "40px",
              width: "80%",
              marginTop: "50px",
              color: "#1D1234",
            }}
          >
            Interested in attending Knight Hacks 2023?
          </h1>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "20px" }}
          >
            <button
              style={{
                backgroundColor: "#1D1234",
                color: "white",
                padding: "15px",
                paddingLeft: "30px",
                paddingRight: "30px",
                fontSize: "15px",
                fontWeight: "900",
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
                zIndex: "100",
              }}
              onClick={() => {
                window.location.href = "/auth";
              }}
            >
              Register Now
            </button>
            <button
              style={{
                backgroundColor: "#1D1234",
                color: "white",
                padding: "15px",
                paddingLeft: "30px",
                paddingRight: "30px",
                fontSize: "15px",
                fontWeight: "900",
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
                zIndex: "100",
              }}
              onClick={() => window.open("https://discord.gg/Kv5g9vf")}
            >
              Join the Discord
            </button>
          </div>
          <div
            style={{
              display: "flex",
              gap: "800px",
              marginTop: "-30px",
              marginBottom: "20px",
            }}
          >
            <img src={Dice} style={{ width: "150px" }} />
            <img src={Spellbook} style={{ width: "150px" }} />
          </div>
        </div>
        <div
          style={{
            height: "100px",
            background: "#7C73AA",
            marginTop: "-20px",
            color: "white",
            display: "flex",
            alignItems: "center",
            paddingLeft: "30px",
          }}
        >
          <h3>Made with ❤️ by Knight Hacks</h3>
        </div>
      </div>
    </div>
  );
}

