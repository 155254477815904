import React, { useState } from "react";
import Background from "./background.png";
import Logo from "./logo.png";
import { Button, FormControl, TextInput } from "@primer/react";
import firebase from "./firebase";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  GithubAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import { MailIcon, LockIcon } from "@primer/octicons-react";

export default function Auth() {
  const auth = getAuth();
  const googleProvider = new GoogleAuthProvider();
  const githubProvider = new GithubAuthProvider();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div style={{ overflowX: "hidden" }}>
      <div
        style={{
          backgroundImage: `url(${Background})`,
          width: "100%",
          height: "600px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom center",
        }}
      >
        <div
          style={{
            width: "60%",
            backgroundColor: "white",
            marginTop: "100px",
            marginLeft: "20%",
            position: "absolute",
            boxShadow: "0px 4px 20px 1px rgba(122, 122, 122, 0.25)",
            borderRadius: "30px",
            paddingTop: "80px",
            paddingBottom: "80px",
          }}
        >
          <img src={Logo} style={{ width: "150px" }} />
          <h1 style={{ fontSize: "25px" }}>Sign in / Create Account</h1>
          <p style={{ width: "70%", marginLeft: "15%" }}>
            Sign in using one of the providers below to apply for Knight Hacks
            or check your status.
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "80%",
              marginLeft: "10%",
              gap: "10px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormControl>
              <FormControl.Label>Email Address</FormControl.Label>
              <TextInput
                placeholder="Email Address"
                leadingVisual={MailIcon}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormControl.Label>Password</FormControl.Label>
              <TextInput
                placeholder="Password"
                leadingVisual={LockIcon}
                value={password}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <div style={{ display: "flex", gap: "20px" }}>
              <Button
                onClick={() => {
                  signInWithEmailAndPassword(auth, email, password)
                    .then((userCredential) => {
                      const user = userCredential.user;
                      toast.success("Successful Login!");
                      navigate("/register");
                    })
                    .catch((error) => {
                      const errorCode = error.code;
                      const errorMessage = error.message;
                      toast.error(errorMessage);
                    });
                }}
              >
                Sign in
              </Button>
              <Button
                onClick={() => {
                  createUserWithEmailAndPassword(auth, email, password)
                    .then((userCredential) => {
                      const user = userCredential.user;
                      toast.success("Successful Sign Up!");
                      navigate("/register");
                    })
                    .catch((error) => {
                      const errorCode = error.code;
                      const errorMessage = error.message;
                      toast.error(errorMessage);
                    });
                }}
              >
                Register
              </Button>
            </div>
            <p
              style={{ textDecoration: "underline" }}
              onClick={() => {
                if (email !== "") {
                  sendPasswordResetEmail(auth, email)
                    .then(() => {
                      toast.success("Password Reset Email Sent!");
                    })
                    .catch((error) => {
                      const errorCode = error.code;
                      const errorMessage = error.message;
                      toast.error(errorMessage);
                    });
                } else {
                  toast.error("Please enter an email address.");
                }
              }}
            >
              Forgot your password?
            </p>
            <h6>OR</h6>
            <Button
              leadingIcon={() => {
                return (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="ionicon"
                    viewBox="0 0 512 512"
                    style={{
                      width: "15px",
                      height: "15px",
                    }}
                  >
                    <path d="M473.16 221.48l-2.26-9.59H262.46v88.22H387c-12.93 61.4-72.93 93.72-121.94 93.72-35.66 0-73.25-15-98.13-39.11a140.08 140.08 0 01-41.8-98.88c0-37.16 16.7-74.33 41-98.78s61-38.13 97.49-38.13c41.79 0 71.74 22.19 82.94 32.31l62.69-62.36C390.86 72.72 340.34 32 261.6 32c-60.75 0-119 23.27-161.58 65.71C58 139.5 36.25 199.93 36.25 256s20.58 113.48 61.3 155.6c43.51 44.92 105.13 68.4 168.58 68.4 57.73 0 112.45-22.62 151.45-63.66 38.34-40.4 58.17-96.3 58.17-154.9 0-24.67-2.48-39.32-2.59-39.96z" />
                  </svg>
                );
              }}
              onClick={() => {
                signInWithPopup(auth, googleProvider)
                  .then((result) => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const credential =
                      GoogleAuthProvider.credentialFromResult(result);
                    const token = credential.accessToken;
                    const user = result.user;
                    toast.success("Successful Login!");
                    navigate("/register");
                  })
                  .catch((error) => {
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    toast.error(errorMessage);
                    const email = error.customData.email;
                    const credential =
                      GoogleAuthProvider.credentialFromError(error);
                    // ...
                  });
              }}
            >
              Sign in w/ Google
            </Button>
            <Button
              leadingIcon={() => {
                return (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="ionicon"
                    viewBox="0 0 512 512"
                    style={{
                      width: "15px",
                      height: "15px",
                    }}
                  >
                    <path d="M256 32C132.3 32 32 134.9 32 261.7c0 101.5 64.2 187.5 153.2 217.9a17.56 17.56 0 003.8.4c8.3 0 11.5-6.1 11.5-11.4 0-5.5-.2-19.9-.3-39.1a102.4 102.4 0 01-22.6 2.7c-43.1 0-52.9-33.5-52.9-33.5-10.2-26.5-24.9-33.6-24.9-33.6-19.5-13.7-.1-14.1 1.4-14.1h.1c22.5 2 34.3 23.8 34.3 23.8 11.2 19.6 26.2 25.1 39.6 25.1a63 63 0 0025.6-6c2-14.8 7.8-24.9 14.2-30.7-49.7-5.8-102-25.5-102-113.5 0-25.1 8.7-45.6 23-61.6-2.3-5.8-10-29.2 2.2-60.8a18.64 18.64 0 015-.5c8.1 0 26.4 3.1 56.6 24.1a208.21 208.21 0 01112.2 0c30.2-21 48.5-24.1 56.6-24.1a18.64 18.64 0 015 .5c12.2 31.6 4.5 55 2.2 60.8 14.3 16.1 23 36.6 23 61.6 0 88.2-52.4 107.6-102.3 113.3 8 7.1 15.2 21.1 15.2 42.5 0 30.7-.3 55.5-.3 63 0 5.4 3.1 11.5 11.4 11.5a19.35 19.35 0 004-.4C415.9 449.2 480 363.1 480 261.7 480 134.9 379.7 32 256 32z" />
                  </svg>
                );
              }}
              onClick={() => {
                signInWithPopup(auth, githubProvider)
                  .then((result) => {
                    // This gives you a GitHub Access Token. You can use it to access the GitHub API.
                    const credential =
                      GithubAuthProvider.credentialFromResult(result);
                    const token = credential.accessToken;
                    const user = result.user;
                    toast.success("Successful Login!");
                    navigate("/register");
                  })
                  .catch((error) => {
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    toast.error(errorMessage);
                    const email = error.customData.email;
                    const credential =
                      GithubAuthProvider.credentialFromError(error);
                  });
              }}
            >
              Sign in w/ Github
            </Button>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
}
