import React, { useState, useEffect } from "react";
import Background from "./background.png";
import Logo from "./logo.png";
import {
  Button,
  TextInput,
  FormControl,
  Select,
  Checkbox,
  Textarea,
  Box,
  ToggleSwitch,
} from "@primer/react";
import firebase from "./firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import { ReplyIcon } from "@primer/octicons-react";
import { useMediaQuery } from "react-responsive";

export default function Register() {
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const [resume, setResume] = useState(null);
  const db = getFirestore();
  const storage = getStorage();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    phoneNumber: "",
    firstName: "",
    lastName: "",
    age: "",
    gender: "",
    ethnicity: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    school: "",
    major: "",
    gradYear: "",
    whyAttend: "",
    whatLearn: "",
    agreeConduct: false,
    shareResume: false,
    resume: null,
    accepted: false,
    confirmed: false,
    denied: false,
    waitlist: true,
    withdrawn: false,
    firstTime: false,
    horsePlinko: false,
    registrationDate: new Date(),
  });
  const [submitted, setSubmitted] = useState(false);
  const [application, setApplication] = useState({});

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setFormData((prevData) => ({ ...prevData, email: user["email"] }));
        const docRef = doc(db, "hackathon", user.email);
        getDoc(docRef).then((doc) => {
          if (doc.exists()) {
            setApplication(doc.data());
            setSubmitted(true);
          }
        });
      }
    });
  }, []);

  const handleInputChange = (e, key) => {
    setFormData({ ...formData, [key]: e.target.value });
  };

  const handleFileChange = (e) => {
    setResume(e.target.files[0]);
  };

  const validateForm = () => {
    const phoneNumberPattern = /^[0-9]{10}$|^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
    const requiredFields = [
      "phoneNumber",
      "firstName",
      "lastName",
      "age",
      "gender",
      "address1",
      "city",
      "zip",
      "school",
      "major",
      "gradYear",
      "whyAttend",
      "whatLearn",
      "agreeConduct",
      "shareResume",
    ];

    for (let field of requiredFields) {
      if (!formData[field]) {
        toast.error(`Please fill in ${field}.`);
        return false;
      }
    }

    if (formData.age < 18) {
      toast.error("Attendees must be over 18.");
      return false;
    }

    if (!phoneNumberPattern.test(formData.phoneNumber)) {
      toast.error("Please enter a valid phone number.");
      return false;
    }

    //check if resume has been uploaded
    if (resume == null) {
      toast.error("Please upload your resume.");
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        // Upload resume to Firebase Storage
        const resumeRef = ref(storage, `resumes/${formData.email}`);
        const uploadData = await uploadBytes(resumeRef, resume, {
          contentType: resume.type, // Use the actual MIME type of the file
        });

        // Upload form data to Firestore
        const updatedFormData = {
          ...formData,
          resume: uploadData.ref.fullPath,
        };

        setDoc(doc(db, "hackathon", formData.email), updatedFormData).then(
          () => {
            toast.success("Form submitted successfully!");
            setSubmitted(true);
          }
        );
      } catch (error) {
        toast.error("An error occurred while submitting the form.");
      }
    }
  };

  const handleResumeChange = async () => {
    try {
      // Upload resume to Firebase Storage
      const resumeRef = ref(storage, `resumes/${application.email}`);
      const uploadData = await uploadBytes(resumeRef, resume, {
        contentType: resume.type, // Use the actual MIME type of the file
      }).then(() => {
        toast.success("Resume updated successfully!");
      });
    } catch {
      toast.error("An error occurred while updating your resume.");
    }
  };

  const handleCheckboxChange = (e, key) => {
    setFormData({ ...formData, [key]: e.target.checked });
  };

  const handleSwitchChange = (e, key) => {
    setFormData({ ...formData, [key]: e });
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <div
        style={{
          backgroundImage: `url(${Background})`,
          width: "100%",
          height: "600px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom center",
        }}
      >
        {!isTabletOrMobile && (
          <div
            style={{
              width: isTabletOrMobile ? "90%" : "80%",
              backgroundColor: "white",
              marginTop: "30px",
              marginLeft: isTabletOrMobile ? "5%" : "10%",
              position: "absolute",
              boxShadow: "0px 4px 20px 1px rgba(122, 122, 122, 0.25)",
              borderRadius: "30px",
              paddingTop: "80px",
              paddingBottom: "80px",
            }}
          >
            {!submitted && (
              <div>
                <h1>Applications for Knight Hacks are closed</h1>
                <p>
                  Unfortunately, we have reached our capacity for the event are
                  no longer accepting applications.
                </p>
              </div>
            )}
            {submitted && (
              <div>
                <img src={Logo} style={{ width: "150px" }} />
                <h1 style={{ fontSize: "25px" }}>
                  Application Successfully submitted!
                </h1>
                <p style={{ width: "40%", marginLeft: "30%" }}>
                  Thank you for applying to Knight Hacks 2023! Your application
                  status is below. If you are confirmed, you are good to go and
                  we will see you at the event! Any other status will not be
                  allowed inside the venue.
                </p>
                <p>
                  Your Current Application Status is:{" "}
                  <b>
                    {application.accepted
                      ? application.confirmed
                        ? "Confirmed - See you at Knight Hacks 2023!"
                        : "Acceptance Cancelled (Did not confirm attendence)"
                      : "Denied (Hit Capacity)"}
                  </b>
                  {application.confirmed && (
                    <div>
                      <hr
                        style={{
                          backgroundColor: "#dedede",
                          marginTop: "40px",
                          marginBottom: "20px",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <h2>Updated your Resume? Switch it out below.</h2>
                        <div
                          style={{
                            marginTop: "0px",
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "column",
                            gap: "20px",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#ededed",
                            width: "500px",
                            paddingTop: "20px",
                            paddingBottom: "20px",
                            borderRadius: "10px",
                          }}
                        >
                          <FormControl style={{ width: "100%" }}>
                            <FormControl.Label>
                              Upload your Resume
                            </FormControl.Label>
                            <input
                              type="file"
                              accept=".pdf"
                              onChange={handleFileChange}
                            />
                          </FormControl>
                        </div>
                        <Button
                          variant="primary"
                          style={{ width: "500px", marginTop: "20px" }}
                          onClick={handleResumeChange}
                        >
                          Submit new Resume
                        </Button>
                      </div>
                      <hr
                        style={{
                          backgroundColor: "#dedede",
                          marginTop: "40px",
                          marginBottom: "20px",
                        }}
                      />
                    </div>
                  )}
                  {application.accepted && application.confirmed == false && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <h2>
                        Confirmations for Knight Hacks have closed for 2023
                      </h2>
                      <p style={{ width: "60%", marginTop: "-10px" }}>
                        Unfortunately, we have reached our capacity for the
                        event are not longer able to allow hackers to confirm
                        their attendence for the event at this time. IF YOU DO
                        NOT ALREADY HAVE A CONFIRMED STATUS, YOU WILL NOT BE
                        ALLOWED IN THE VENUE.
                      </p>
                      <Button
                        variant="primary"
                        style={{ width: "50%" }}
                        disabled={true}
                      >
                        Confirm Attendance
                      </Button>
                    </div>
                  )}
                  {application.accepted && application.confirmed == true && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <h2>Can't go to Knight Hacks 2023 anymore?</h2>
                      <p style={{ width: "60%", marginTop: "-10px" }}>
                        If you can no longer attend our event, please withdraw
                        your confirmation by clicking the button below. This
                        will free up your spot to someone else who wants it.
                      </p>
                      <Button
                        variant="danger"
                        style={{ width: "50%" }}
                        onClick={() => {
                          setDoc(doc(db, "hackathon", user.email), {
                            ...application,
                            confirmed: false,
                            withdrawn: true,
                          }).then(() => {
                            toast.success(
                              "Confirmation withdrawn successfully!"
                            );
                            setApplication({
                              ...application,
                              confirmed: false,
                              withdrawn: true,
                            });
                          });
                        }}
                      >
                        Withdraw Confirmation
                      </Button>
                    </div>
                  )}
                  {application.accepted && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <h2 style={{ marginTop: "40px" }}>
                        Check out the 2023 Hacker's Guide
                      </h2>
                      <p style={{ width: "60%", marginTop: "-10px" }}>
                        A hacker's guide is where you will find everything you
                        need to know about the event from prizes to parking to
                        everything in-between. Try to check for information here
                        before asking organizers.
                      </p>
                      <Button
                        variant={application.confirmed ? "primary" : "default"}
                        style={{ width: "50%" }}
                        onClick={() => {
                          window.open(
                            "https://knight-hacks.notion.site/Hackers-Guide-0d52c14d1e2a406e8ad5203a94c45212?pvs=4"
                          );
                        }}
                      >
                        Open the Hacker's Guide
                      </Button>
                    </div>
                  )}
                  {application.accepted && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <h2 style={{ marginTop: "40px" }}>Join the Discord</h2>
                      <p style={{ width: "60%", marginTop: "-10px" }}>
                        We send all hackathon-related updates and announcements
                        through our Discord server so make sure to join. Also,
                        we will have channels for team-building, talking to
                        sponsors, Q&A, support from knowledgable mentors as we
                        get closer to the event.
                      </p>
                      <Button
                        style={{ width: "50%" }}
                        onClick={() => {
                          window.open("https://discord.gg/Kv5g9vf");
                        }}
                      >
                        Join the Knight Hacks Server
                      </Button>

                      {/* <h2 style={{marginTop: '40px'}}>Share your Acceptance</h2>
                    <p style={{width: '60%', marginTop: '-10px'}}>Spread the word about your acceptance on Social Media!! Getting accepted to a hackathon is no small feat and recruiters from companies love to see that you are taking the initiative when making hiring decisions. Share the following graphic on LinkedIn, Twitter, or any other social media platform.</p>
                    <Button style={{ width: "50%" }} onClick={() => {
    
                    }}>Download Social Media Graphics</Button> */}
                    </div>
                  )}
                  <hr
                    style={{
                      backgroundColor: "#dedede",
                      marginTop: "40px",
                      marginBottom: "20px",
                    }}
                  />
                </p>
                {!application.accepted && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <h4>In the meantime...</h4>
                      <h2 style={{ marginTop: "-20px" }}>Join our Discord</h2>
                      <p style={{ marginTop: "-10px", width: "50%" }}>
                        We use Discord to send out annoucements, do pre-event
                        teambuilding, answer questions, and more.
                      </p>
                      <Button
                        style={{ width: "50%" }}
                        onClick={() => {
                          window.open("https://discord.gg/Kv5g9vf");
                        }}
                      >
                        Join the Knight Hacks Discord
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        )}
        {isTabletOrMobile && (
          <div
            style={{
              width: "90%",
              backgroundColor: "white",
              marginTop: "30px",
              marginLeft: isTabletOrMobile ? "5%" : "10%",
              position: "absolute",
              boxShadow: "0px 4px 20px 1px rgba(122, 122, 122, 0.25)",
              borderRadius: "30px",
              paddingTop: "80px",
              paddingBottom: "80px",
            }}
          >
            {submitted && (
              <div>
                <img src={Logo} style={{ width: "150px" }} />
                <h1 style={{ fontSize: "25px" }}>
                  Welcome, {application.firstName}!
                </h1>
                <p style={{ width: "40%", marginLeft: "30%" }}>
                  Thank you for applying to Knight Hacks 2023! Your application
                  status is below. If you are confirmed, you are good to go and
                  we will see you at the event! Any other status will not be
                  allowed inside the venue.
                </p>
                <p>
                  Your Current Application Status is:{" "}
                  <b>
                    {application.accepted
                      ? application.confirmed
                        ? "Confirmed - See you at Knight Hacks 2023!"
                        : "Acceptance Cancelled (Did not confirm attendence)"
                      : "Denied (Hit Capacity)"}
                  </b>
                  {application.confirmed && (
                    <div>
                      <hr
                        style={{
                          backgroundColor: "#dedede",
                          marginTop: "40px",
                          marginBottom: "20px",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <h2>Updated your Resume? Switch it out below.</h2>
                        <div
                          style={{
                            marginTop: "0px",
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "column",
                            gap: "20px",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#ededed",
                            width: "350px",
                            paddingTop: "20px",
                            paddingBottom: "20px",
                            borderRadius: "10px",
                          }}
                        >
                          <FormControl style={{ width: "100%" }}>
                            <FormControl.Label>
                              Upload your Resume
                            </FormControl.Label>
                            <input
                              type="file"
                              accept=".pdf"
                              onChange={handleFileChange}
                            />
                          </FormControl>
                        </div>
                        <Button
                          variant="primary"
                          style={{ width: "350px", marginTop: "20px" }}
                          onClick={handleResumeChange}
                        >
                          Submit new Resume
                        </Button>
                      </div>
                      <hr
                        style={{
                          backgroundColor: "#dedede",
                          marginTop: "40px",
                          marginBottom: "20px",
                        }}
                      />
                    </div>
                  )}
                  {application.accepted && application.confirmed == false && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <h2>
                        Confirmations for Knight Hacks have closed for 2023
                      </h2>
                      <p style={{ width: "60%", marginTop: "-10px" }}>
                        Unfortunately, we have reached our capacity for the
                        event are not longer able to allow hackers to confirm
                        their attendence for the event at this time. IF YOU DO
                        NOT ALREADY HAVE A CONFIRMED STATUS, YOU WILL NOT BE
                        ALLOWED IN THE VENUE.
                      </p>
                      <Button
                        variant="primary"
                        style={{ width: "50%" }}
                        disabled={true}
                      >
                        Confirm Attendance
                      </Button>
                    </div>
                  )}
                  {application.accepted && application.confirmed == true && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <h2>Can't go to Knight Hacks 2023 anymore?</h2>
                      <p style={{ width: "60%", marginTop: "-10px" }}>
                        If you can no longer attend our event, please withdraw
                        your confirmation by clicking the button below. This
                        will free up your spot to someone else who wants it.
                      </p>
                      <Button
                        variant="danger"
                        style={{ width: "50%" }}
                        onClick={() => {
                          setDoc(doc(db, "hackathon", user.email), {
                            ...application,
                            confirmed: false,
                            withdrawn: true,
                          }).then(() => {
                            toast.success(
                              "Confirmation withdrawn successfully!"
                            );
                            setApplication({
                              ...application,
                              withdrawn: true,
                              confirmed: false,
                            });
                          });
                        }}
                      >
                        Withdraw Confirmation
                      </Button>
                    </div>
                  )}
                  {application.accepted && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <h2 style={{ marginTop: "40px" }}>
                        Check out the 2023 Hacker's Guide
                      </h2>
                      <p style={{ width: "60%", marginTop: "-10px" }}>
                        A hacker's guide is where you will find everything you
                        need to know about the event from prizes to parking to
                        everything in-between. Try to check for information here
                        before asking organizers.
                      </p>
                      <Button
                        variant={application.confirmed ? "primary" : "default"}
                        style={{ width: "50%" }}
                        onClick={() => {
                          window.open(
                            "https://knight-hacks.notion.site/Hackers-Guide-0d52c14d1e2a406e8ad5203a94c45212?pvs=4"
                          );
                        }}
                      >
                        Open the Hacker's Guide
                      </Button>
                    </div>
                  )}
                  {application.accepted && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <h2 style={{ marginTop: "40px" }}>Join the Discord</h2>
                      <p style={{ width: "60%", marginTop: "-10px" }}>
                        We send all hackathon-related updates and announcements
                        through our Discord server so make sure to join. Also,
                        we will have channels for team-building, talking to
                        sponsors, Q&A, support from knowledgable mentors as we
                        get closer to the event.
                      </p>
                      <Button
                        style={{ width: "50%" }}
                        onClick={() => {
                          window.open("https://discord.gg/Kv5g9vf");
                        }}
                      >
                        Join the Knight Hacks Server
                      </Button>

                      {/* <h2 style={{marginTop: '40px'}}>Share your Acceptance</h2>
                    <p style={{width: '60%', marginTop: '-10px'}}>Spread the word about your acceptance on Social Media!! Getting accepted to a hackathon is no small feat and recruiters from companies love to see that you are taking the initiative when making hiring decisions. Share the following graphic on LinkedIn, Twitter, or any other social media platform.</p>
                    <Button style={{ width: "50%" }} onClick={() => {
    
                    }}>Download Social Media Graphics</Button> */}
                    </div>
                  )}
                  <hr
                    style={{
                      backgroundColor: "#dedede",
                      marginTop: "40px",
                      marginBottom: "20px",
                    }}
                  />
                </p>
                {!application.accepted && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <h4>In the meantime...</h4>
                      <h2 style={{ marginTop: "-20px" }}>Join our Discord</h2>
                      <p style={{ marginTop: "-10px", width: "50%" }}>
                        We use Discord to send out annoucements, do pre-event
                        teambuilding, answer questions, and more.
                      </p>
                      <Button
                        style={{ width: "50%" }}
                        onClick={() => {
                          window.open("https://discord.gg/Kv5g9vf");
                        }}
                      >
                        Join the Knight Hacks Discord
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <Toaster />
    </div>
  );
}
